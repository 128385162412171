import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css'; // Import the CSS file


const AvailableBeds = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [reservations, setReservations] = useState([]);
  const [availableBeds, setAvailableBeds] = useState({});
  const [lits, setLits] = useState([]);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchLits = async () => {
      try {
        const response = await axios.get('/api/lits/', {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        setLits(response.data);
      } catch (error) {
        console.error('Error fetching lits:', error);
        setMessage('Je n\'ai pas pu ouvrir les datas.');
      }
    };

    fetchLits();
  }, []);

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Vérifier si la date de fin est avant la date de début
    if (endDateObj < startDateObj) {
      setMessage('La date de fin doit être après la date de début.');
      return;
    }

    try {
      const response = await axios.get('/api/reservations/', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setReservations(response.data);
      filterAvailableBeds(response.data);
    } catch (error) {
      console.error('Error fetching reservations:', error);
      setMessage('Erreur lors de la récupération des réservations.');
    }
  };

  const filterAvailableBeds = (reservations) => {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    const occupiedBeds = reservations.filter((reservation) => {
      if (reservation.facture === "annulée") return false;
      const resStartDate = new Date(reservation.start_date);
      const resEndDate = new Date(reservation.end_date);

      return (
        (resStartDate <= endDateObj && resStartDate >= startDateObj) ||
        (resEndDate >= startDateObj && resEndDate <= endDateObj) ||
        (resStartDate <= startDateObj && resEndDate >= endDateObj)
      );
    }).map(reservation => reservation.lit__lit_number);

    const availableBeds = lits.filter(lit => !occupiedBeds.includes(lit.lit_number));

    // Grouper les lits par chambre
    const groupedBeds = availableBeds.reduce((acc, bed) => {
      if (!acc[bed.chambre]) {
        acc[bed.chambre] = [];
      }
      acc[bed.chambre].push(bed.lit_number);
      return acc;
    }, {});

    setAvailableBeds(groupedBeds);

    if (Object.keys(groupedBeds).length === 0) {
      setMessage('Aucun lit disponible pour les dates sélectionnées.');
    } else {
      setMessage('');
    }
  };

  return (
    <div className="container">
      <h1>Disponibilité des lits</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="start_date">Date de début</label>
          <input type="date" name="start_date" value={startDate} onChange={handleStartDateChange} />
        </div>
        <div>
          <label htmlFor="end_date">Date de fin</label>
          <input type="date" name="end_date" value={endDate} onChange={handleEndDateChange} />
        </div>
        <button type="submit">Vérifier la disponibilité</button>
      </form>
      {message && <p>{message}</p>}
      <ul>
        {Object.keys(availableBeds).map(chambre => (
          <li key={chambre}>
            <p>Chambre: {chambre}</p>
            <p>Lits: {availableBeds[chambre].join(', ')}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AvailableBeds;

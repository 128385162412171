import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import './styles.css'; // Import the CSS file


const ModifyReservation = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [formData, setFormData] = useState({});
	const [chambres, setChambres] = useState([]);
	const [litsByChambre, setLitsByChambre] = useState({});
	const [availableLits, setAvailableLits] = useState([]);
	const [reservations, setReservations] = useState([]);
	const [message, setMessage] = useState('');
	const [csrfToken, setCsrfToken] = useState('');

	useEffect(() => {
		const fetchInitialData = async () => {
			try {
				const tokenResponse = await axios.get('/api/get-csrf-token/');
				const token = tokenResponse.data.csrfToken;
				setCsrfToken(token);

				const reservationResponse = await axios.get(`/api/reservations/${id}/`, {
					headers: { 'X-CSRFToken': token, 'Content-Type': 'application/json' },
					withCredentials: true,
				});
				setFormData({
					...reservationResponse.data,
					start_date: reservationResponse.data.start_date,
					end_date: reservationResponse.data.end_date,
				});

				const litsResponse = await axios.get('/api/lits/', {
					headers: { 'X-CSRFToken': token, 'Content-Type': 'application/json' },
					withCredentials: true,
				});

				const uniqueChambres = new Set();
				const litsMap = {};
				litsResponse.data.forEach(item => {
					uniqueChambres.add(item.chambre);
					litsMap[item.chambre] = [...(litsMap[item.chambre] || []), item.lit_number];
				});
				setChambres([...uniqueChambres]);
				setLitsByChambre(litsMap);

				if (reservationResponse.data.lit__chambre) {
					setAvailableLits(litsMap[reservationResponse.data.lit__chambre]);
				}

				const reservationsResponse = await axios.get('/api/reservations/', {
					headers: { 'X-CSRFToken': token, 'Content-Type': 'application/json' },
					withCredentials: true,
				});
				setReservations(reservationsResponse.data);

			} catch (error) {
				console.error('Error fetching reservation:', error);
				setMessage('Je n\'ai pas pu chargé les datas.');
			}
		};

		fetchInitialData();
	}, [id]);

	const handleChange = e => {
		const { name, value } = e.target;
		setFormData(prev => ({ ...prev, [name]: value }));

		if (name === 'lit__chambre') {
			setAvailableLits(litsByChambre[value] || []);
		}
	};

	const handleSubmit = e => {
		e.preventDefault();

		const startDate = new Date(formData.start_date);
		const endDate = new Date(formData.end_date);

		const isBooked = reservations.some(reservation => {
			if (reservation.id === parseInt(id)) return false;
			if (reservation.facture === "annulée") return false;
			const resStart = new Date(reservation.start_date);
			const resEnd = new Date(reservation.end_date);
			return reservation.lit__lit_number === parseInt(formData.lit__lit_number) && (
				(resStart <= endDate && resStart >= startDate) ||
				(resEnd >= startDate && resEnd <= endDate) ||
				(resStart <= startDate && resEnd >= endDate)
			);
		});

		if (isBooked) {
			setMessage('Ce lit est déjà réservé pour les dates sélectionnées. Veuillez choisir d’autres dates ou un autre lit.');
			return;
		}

		axios.post(`/api/reservations/update/${id}/`, formData, {
			headers: { 'X-CSRFToken': csrfToken, 'Content-Type': 'application/json' },
			withCredentials: true,
		})
			.then(response => {
				setMessage('Réservation modifiée avec succès.');
				setTimeout(() => {
					navigate('/'); // Rediriger vers la vue des réservations après un délai
				}, 2000);
			})
			.catch(error => {
				console.error('Error modifying reservation:', error);
				setMessage('Échec de la modification de la réservation.');
			});
	};

	const handleCancel = () => {
		navigate('/'); // Naviguer vers la vue des réservations
	};

	return (
		<div className="container">
			<h1>Modifier une réservation</h1>
			{message && <p>{message}</p>}
			<form onSubmit={handleSubmit}>
				<div>
					<label htmlFor="lit__chambre">Chambre</label>
					<select name="lit__chambre" onChange={handleChange} value={formData.lit__chambre || ''}>
						<option value="">Sélectionnez une chambre</option>
						{chambres.map(chambre => (
							<option key={chambre} value={chambre}>{chambre}</option>
						))}
					</select>
				</div>
				<div>
					<label htmlFor="lit__lit_number">Lit</label>
					<select name="lit__lit_number" onChange={handleChange} value={formData.lit__lit_number || ''}>
						<option value="">Sélectionnez un lit</option>
						{availableLits.map(lit => (
							<option key={lit} value={lit}>{lit}</option>
						))}
					</select>
				</div>
				<div>
					<label htmlFor="start_date">Date de début</label>
					<input type="date" name="start_date" onChange={handleChange} value={formData.start_date || ''} />
				</div>
				<div>
					<label htmlFor="end_date">Date de fin</label>
					<input type="date" name="end_date" onChange={handleChange} value={formData.end_date || ''} />
				</div>
				<div>
					<label htmlFor="facture">Statut de la facture</label>
					<select name="facture" onChange={handleChange} value={formData.facture || ''}>
						<option value="">Sélectionnez un statut</option>
						<option value="à payer">À payer</option>
						<option value="payée">Payée</option>
						<option value="annulée">Annulée</option>
						<option value="archivée">Archivée</option>
					</select>
				</div>
				<button type="submit">Modifier</button>
				<button type="button" onClick={handleCancel}>Annuler</button>
			</form>
		</div>
	);
};

export default ModifyReservation;

import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.css"

const Header = () => {
	const navigate = useNavigate();

	return (
		<header>
			<nav>
				<button onClick={() => navigate("/")}>Toutes les Réservations</button>
				<button onClick={() => navigate("/create")}>Créer une Réservation</button>
				<button onClick={() => navigate("/available")}>Lits disponibles</button>
				<button onClick={() => navigate("/purge-reservations")}>Nettoyage</button>
			</nav>
		</header>
	);
};

export default Header;

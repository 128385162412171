import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css'; // Import the CSS file

const PurgeReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [selectedReservations, setSelectedReservations] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('all'); // État pour le statut de la facture
  const [message, setMessage] = useState('');
  const [csrfToken, setCsrfToken] = useState('');

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        // Fetch CSRF token
        const tokenResponse = await axios.get('/api/get-csrf-token/');
        const token = tokenResponse.data.csrfToken;
        setCsrfToken(token);

        // Fetch reservations
        const response = await axios.get('/api/reservations/', {
          headers: { 'X-CSRFToken': token, 'Content-Type': 'application/json' },
          withCredentials: true,
        });
        setReservations(response.data);
      } catch (error) {
        console.error('Error fetching reservation:', error);
        setMessage('Je n\'ai pas pu charger les données.');
      }
    };

    fetchReservations();
  }, []);

  const handleCheckboxChange = (reservationId) => {
    setSelectedReservations(prevSelected => {
      if (prevSelected.includes(reservationId)) {
        return prevSelected.filter(id => id !== reservationId);
      } else {
        return [...prevSelected, reservationId];
      }
    });
  };

  const handlePurge = async () => {
    try {
      const response = await axios.post('/api/reservations/purge-cancelled/', {
        reservations: selectedReservations
      }, {
        headers: { 'X-CSRFToken': csrfToken, 'Content-Type': 'application/json' },
        withCredentials: true,
      });

      if (response.status === 200) { // Vérifiez si la réponse est un succès
        setMessage('Selected reservations have been purged.');
        // Mise à jour de la liste des réservations
        setReservations(prevReservations => prevReservations.filter(reservation => !selectedReservations.includes(reservation.id)));
        setSelectedReservations([]);
      } else {
        setMessage('Failed to purge reservations.');
      }
    } catch (error) {
      console.error('Error purging reservations:', error);
      setMessage('Failed to purge reservations.');
    }
  };

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const filteredReservations = selectedStatus === 'all'
    ? reservations
    : reservations.filter(reservation => reservation.facture === selectedStatus);

  return (
    <div className="container">
      <h1>Purge des réservations</h1>
      {message && <p>{message}</p>}
      <label>
        Filtrer par statut de la facture :
        <select value={selectedStatus} onChange={handleStatusChange}>
          <option value="all">Tous les états</option>
          <option value="payée">Payée</option>
          <option value="à payer">À payer</option>
          <option value="annulée">Annulée</option>
          <option value="archivée">Archivée</option>
        </select>
      </label>
      {filteredReservations.length === 0 ? (
        <p>Aucune réservation trouvée.</p>
      ) : (
        <ul>
          {filteredReservations.map(reservation => (
            <li key={reservation.id}>
              <input
                type="checkbox"
                checked={selectedReservations.includes(reservation.id)}
                onChange={() => handleCheckboxChange(reservation.id)}
              />
              <span>{`Chambre: ${reservation.lit__chambre}, Lit: ${reservation.lit__lit_number}, Date de début: ${reservation.start_date}, Date de fin: ${reservation.end_date}, Commentaires: ${reservation.comment}`}</span>
            </li>
          ))}
        </ul>
      )}
      <button onClick={handlePurge}>Purger les réservations sélectionnées</button>
    </div>
  );
};

export default PurgeReservations;

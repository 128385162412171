import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import './styles.css'; // Import the CSS file

const ViewAllReservations = () => {
  const [reservations, setReservations] = useState([]);
  const [sortedReservations, setSortedReservations] = useState([]);
  const [sortConfig, setSortConfig] = useState(null);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const [filterStatus, setFilterStatus] = useState("");

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await axios.get("/api/reservations/", {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        });
        // console.log("Fetched reservations:", response.data); // Log fetched data
        setReservations(response.data);
        setSortedReservations(response.data);
      } catch (error) {
        console.error("Error fetching reservations:", error);
        setMessage("Je n'ai pas pu ouvrir les données.");
      }
    };

    fetchReservations();
  }, []);

  const handleFilterChange = (e) => {
    setFilterStatus(e.target.value);
  };

  const compareValues = (a, b, key, direction) => {
    let aVal = a[key];
    let bVal = b[key];

    if (key === "start_date" || key === "end_date") {
      aVal = new Date(aVal);
      bVal = new Date(bVal);
    } else {
      aVal = parseFloat(aVal);
      bVal = parseFloat(bVal);
    }

    // console.log(`Comparing ${aVal} and ${bVal} for key ${key} in ${direction} direction`);

    if (direction === "ascending") {
      return aVal - bVal;
    } else {
      return bVal - aVal;
    }
  };

  useEffect(() => {
    // console.log("Filter status changed:", filterStatus); // Log filter status change
    // console.log("Original reservations before filtering:", reservations); // Log original data before filtering

    let filteredReservations = [...reservations];

    if (filterStatus !== "") {
      filteredReservations = reservations.filter((reservation) => reservation.facture === filterStatus);
    }

    // console.log("Filtered reservations:", filteredReservations); // Log filtered data

    if (sortConfig !== null) {
      filteredReservations.sort((a, b) => compareValues(a, b, sortConfig.key, sortConfig.direction));
    }

    // console.log("Sorted reservations:", filteredReservations); // Log sorted data
    setSortedReservations(filteredReservations);
  }, [filterStatus, reservations, sortConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig && sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    // console.log("Sort requested:", { key, direction }); // Log sort request
    setSortConfig({ key, direction });
  };

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('fr-FR', options);
  };

  return (
    <div className="container">
      <h1>Voir toutes les réservations</h1>
      {message && <p>{message}</p>}
      <div>
        <label htmlFor="filterStatus">Filtrer par état de réservation</label>
        <select
          name="filterStatus"
          onChange={handleFilterChange}
          value={filterStatus}
        >
          <option value="">Tous les états</option>
          <option value="payée">Payée</option>
          <option value="à payer">À payer</option>
          <option value="annulée">Annulée</option>
          <option value="archivée">Archivée</option>
        </select>
      </div>
      <table>
        <thead>
          <tr>
            <th scope="col" onClick={() => requestSort("reservation_number")}>
              N° de réservation
            </th>
            <th scope="col" onClick={() => requestSort("lit__chambre")}>
              Chambre
            </th>
            <th scope="col" onClick={() => requestSort("lit__lit_number")}>
              Lit
            </th>
            <th scope="col" onClick={() => requestSort("start_date")}>
              Du
            </th>
            <th scope="col" onClick={() => requestSort("end_date")}>
              au
            </th>
            <th scope="col" onClick={() => requestSort("comment")}>
              Commentaires
            </th>
            <th scope="col" onClick={() => requestSort("facture")}>
              État
            </th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedReservations.map((reservation, index) => (
            <tr key={reservation.id || index}>
              <td>{reservation.reservation_number}</td>
              <td>{reservation.lit__chambre}</td>
              <td>{reservation.lit__lit_number}</td>
              <td>{formatDate(reservation.start_date)}</td>
              <td>{formatDate(reservation.end_date)}</td>
              <td>{reservation.comment}</td>
              <td>{reservation.facture}</td>
              <td>
                <button onClick={() => navigate(`/modify/${reservation.id}`)}>
                  Modifier
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewAllReservations;

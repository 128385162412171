import axios from "axios";
import React, { useEffect, useState } from "react";
import './styles.css'; // Import the CSS file

const CreateReservation = () => {
  axios.defaults.withCredentials = true;
  const [formData, setFormData] = useState({
    lit__chambre: "",
    lit__lit_number: "",
    reservation_type: "Chambre",
    startDate: "",
    endDate: "",
    reservation_number: "",
    comment: "",
  });
  const [chambres, setChambres] = useState([]);
  const [litsByChambre, setLitsByChambre] = useState({});
  const [availableLits, setAvailableLits] = useState([]);
  const [message, setMessage] = useState("");
  const [csrfToken, setCsrfToken] = useState("");
  const [reservations, setReservations] = useState([]);
  const [lastReservationNumber, setLastReservationNumber] = useState(0);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const tokenResponse = await axios.get("/api/get-csrf-token/");
        const token = tokenResponse.data.csrfToken;
        setCsrfToken(token);

        const [litsResponse, reservationsResponse] = await Promise.all([
          axios.get("/api/lits/", {
            headers: {
              "X-CSRFToken": token,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }),
          axios.get("/api/reservations/", {
            headers: {
              "X-CSRFToken": token,
              "Content-Type": "application/json",
            },
            withCredentials: true,
          }),
        ]);

        const uniqueChambres = new Set();
        const litsMap = {};
        litsResponse.data.forEach((item) => {
          uniqueChambres.add(item.chambre);
          litsMap[item.chambre] = [
            ...(litsMap[item.chambre] || []),
            item.lit_number,
          ];
        });
        setChambres([...uniqueChambres]);
        setLitsByChambre(litsMap);

        setReservations(reservationsResponse.data);
        if (reservationsResponse.data.length > 0) {
          const lastResNumber = parseInt(
            reservationsResponse.data[reservationsResponse.data.length - 1]
              .reservation_number
          );
          // console.log('Last Reservation Number:', lastResNumber);
          setLastReservationNumber(lastResNumber);
        }


        if (formData.lit__chambre) {
          setAvailableLits(litsMap[formData.lit__chambre]);
        }
      } catch (error) {
        console.error("Error initializing data:", error);
        setMessage("Je n'ai pas réussi à lire les datas.");
      }
    };

    fetchInitialData();
  }, [formData.lit__chambre]);

  const updateLastReservationNumber = async () => {
    try {
      const response = await axios.get('/api/reservations/', {
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      const reservations = response.data;
      if (reservations.length > 0) {
        const lastResNumber = parseInt(
          reservations[reservations.length - 1].reservation_number
        );
        setLastReservationNumber(lastResNumber);
      } else {
        setLastReservationNumber(1); // Si aucune réservation n'existe encore
      }
    } catch (error) {
      console.error("Error updating last reservation number:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    if (name === "lit__chambre") {
      setAvailableLits(litsByChambre[value] || []);
    }
  };

  const handleReservationTypeChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      reservation_type: value,
      lit__chambre: "",
      lit__lit_number: ""
    }));
    setAvailableLits([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await updateLastReservationNumber();

    if (new Date(formData.startDate) > new Date(formData.endDate)) {
      setMessage("La date de début doit être avant la date de fin.");
      return;
    }

    const startDate = new Date(formData.startDate);
    const endDate = new Date(formData.endDate);
    const litsForChambre = litsByChambre[formData.lit__chambre] || [];

    try {
      if (formData.reservation_type === "gestion_libre") {
        let allAvailable = true;
        for (const chambre in litsByChambre) {
          for (const lit of litsByChambre[chambre]) {
            const isBooked = reservations.some((reservation) => {
              if (reservation.facture === "annulée") return false;
              const resStart = new Date(reservation.start_date);
              const resEnd = new Date(reservation.end_date);
              return (
                reservation.lit__lit_number === lit &&
                ((resStart <= endDate && resStart >= startDate) ||
                  (resEnd >= startDate && resEnd <= endDate) ||
                  (resStart <= startDate && resEnd >= endDate))
              );
            });
            if (isBooked) {
              setMessage(
                `Au moins un lit dans la chambre ${chambre} n'est pas disponible.`,
              );
              allAvailable = false;
              break;
            }
          }
          if (!allAvailable) break;
        }
        if (allAvailable) {
          for (const chambre in litsByChambre) {
            for (const lit of litsByChambre[chambre]) {
              const reservationNumber = lastReservationNumber + 1;
              setLastReservationNumber(reservationNumber); // Update lastReservationNumber
              try {
                const response = await axios.post(
                  `https://www.dragonpong.xyz/api/addreservation/`,
                  {
                    lit__chambre: chambre,
                    lit__lit_number: lit,
                    start_date: formData.startDate,
                    end_date: formData.endDate,
                    reservation_number: reservationNumber,
                    comment: formData.comment,
                  },
                  {
                    headers: {
                      "X-CSRFToken": csrfToken,
                      "Content-Type": "application/json",
                    },
                    withCredentials: true,
                  },
                );
                console.log(
                  "Reservation created successfully for lit:",
                  lit,
                  response.data,
                );
                setMessage(
                  "Toutes les réservations ont été créées avec succès pour la gestion libre.",
                );
              } catch (error) {
                console.error(
                  "Failed to create reservation for lit:",
                  lit,
                  error,
                );
                setMessage(
                  "Erreur lors de la création de la réservation. Veuillez réessayer.",
                );
              }
            }
          }
        }
      } else if (formData.reservation_type === "Chambre") {
        let allAvailable = true;
        for (const lit of litsForChambre) {
          const isBooked = reservations.some((reservation) => {
            if (reservation.facture === "annulée") return false;
            const resStart = new Date(reservation.start_date);
            const resEnd = new Date(reservation.end_date);
            return (
              reservation.lit__lit_number === lit &&
              ((resStart <= endDate && resStart >= startDate) ||
                (resEnd >= startDate && resEnd <= endDate) ||
                (resStart <= startDate && resEnd >= endDate))
            );
          });
          if (isBooked) {
            setMessage(
              `Au moins un lit dans la chambre ${formData.lit__chambre} n'est pas disponible.`,
            );
            allAvailable = false;
            break;
          }
        }

        if (allAvailable) {
          for (const lit of litsForChambre) {
            const reservationNumber = lastReservationNumber + 1;
            setLastReservationNumber(reservationNumber); // Update lastReservationNumber
            try {
              const response = await axios.post(
                `https://www.dragonpong.xyz/api/addreservation/`,
                {
                  lit__chambre: formData.lit__chambre,
                  lit__lit_number: lit,
                  start_date: formData.startDate,
                  end_date: formData.endDate,
                  reservation_number: reservationNumber,
                  comment: formData.comment,
                },
                {
                  headers: {
                    "X-CSRFToken": csrfToken,
                    "Content-Type": "application/json",
                  },
                  withCredentials: true,
                },
              );
              console.log(
                "Reservation created successfully for lit:",
                lit,
                response.data,
              );
              setMessage("Toutes les réservations ont été créées avec succès pour la chambre.");
            } catch (error) {
              console.error(
                "Failed to create reservation for lit:",
                lit,
                error,
              );
              setMessage(
                "Erreur lors de la création de la réservation. Veuillez réessayer.",
              );
            }
          }
        }
      } else if (formData.reservation_type === "Lit") {
        const isBooked = reservations.some((reservation) => {
          if (reservation.facture === "annulée") return false;
          const resStart = new Date(reservation.start_date);
          const resEnd = new Date(reservation.end_date);
          return (
            reservation.lit__lit_number === parseInt(formData.lit__lit_number) &&
            ((resStart <= endDate && resStart >= startDate) ||
              (resEnd >= startDate && resEnd <= endDate) ||
              (resStart <= startDate && resEnd >= endDate))
          );
        });
        // console.log("reservations : ", reservations);
        if (isBooked) {
          setMessage(
            "Ce lit est déjà réservé pour les dates sélectionnées. Veuillez choisir d’autres dates ou un autre lit.",
          );
          return;
        }

        const reservationNumber = lastReservationNumber + 1;
        setLastReservationNumber(reservationNumber); // Update lastReservationNumber
        try {
          const response = await axios.post(
            `https://www.dragonpong.xyz/api/addreservation/`,
            {
              lit__chambre: formData.lit__chambre,
              lit__lit_number: formData.lit__lit_number,
              start_date: formData.startDate,
              end_date: formData.endDate,
              reservation_number: reservationNumber,
              comment: formData.comment,
            },
            {
              headers: {
                "X-CSRFToken": csrfToken,
                "Content-Type": "application/json",
              },
              withCredentials: true,
            },
          );
          console.log("Reservation formData", formData);
          console.log("Reservation created successfully:", response.data);
          setMessage("La réservation a bien été crée pour le lit!");
        } catch (error) {
          console.error(
            "Failed to create reservation:",
            error.response ? error.response.data : error,
          );
          setMessage(
            "Erreur lors de la création de la réservation. Veuillez réessayer.",
          );
        }
      }
    } catch (error) {
      console.error("Unexpected error:", error);
      setMessage("Erreur lors de la création de la réservation. Veuillez réessayer.");
    }
  };


  return (
    <div className="container">
      <h1>Créer une réservation</h1>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <label>
          Type:
          <select
            name="reservation_type"
            value={formData.reservation_type}
            onChange={handleReservationTypeChange}
          >
            <option value="Chambre">Chambre</option>
            <option value="Lit">Lit</option>
            <option value="gestion_libre">Gestion Libre</option>
          </select>
        </label>
        <select
          name="lit__chambre"
          value={formData.lit__chambre}
          onChange={handleChange}
        >
          <option value="">Sélectionner une chambre</option>
          {chambres.map((chambre, index) => (
            <option key={index} value={chambre}>{`Chambre ${chambre}`}</option>
          ))}
        </select>
        {formData.reservation_type === "Lit" && (
          <label>
            Lit:
            <select
              name="lit__lit_number"
              value={formData.lit__lit_number}
              onChange={handleChange}
            >
              <option value="">Sélectionner un lit</option>
              {availableLits.map((lit_number, index) => (
                <option key={index} value={lit_number}>{`${lit_number}`}</option>
              ))}
            </select>
          </label>
        )}
        <label>
          Start Date:
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
          />
        </label>
        <label>
          Commentaire (facultatif):
          <input
            type="text"
            name="comment"
            value={formData.comment}
            onChange={handleChange}
          />
        </label>
        <button type="submit">Submit</button>
        {message && <p>{message}</p>}
      </form>
    </div>
  );
};

export default CreateReservation;

import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CreateReservation from "./CreateReservation";
import Header from "./Header";
import ModifyReservation from "./ModifyReservation";
import ViewAllReservations from "./ViewAllReservations";
import AvailableBeds from "./AvailableBeds";
import PurgeReservations from './PurgeReservations';
import "../components/styles.css"

function App() {
	return (
		<Router>
			<Header />
			<Routes>
				<Route path="/create" element={<CreateReservation />} />
				<Route path="/modify/:id" element={<ModifyReservation />} />
				<Route path="/available" element={<AvailableBeds />} />
				<Route path="/purge-reservations" element={<PurgeReservations />} />
				<Route path="/" element={<ViewAllReservations />} />
			</Routes>
		</Router>
	);
}

export default App;
